<template>
    <div v-if="config?.public?.feature?.mobileApp" class="flex flex-col items-center justify p-5 linear-gradient">
        <div class="flex flex-col lg:flex-row items-center justify-between w-full md:w-156 lg:w-239 2xl:w-322">
            <div class="flex flex-col my-8 sm:my-15 w-full lg:w-1/2 pr-5 sm:pr-20">
                <h2 class="text-4xl sm:text-6xl font-bold font-heading">
                    {{ $t('text-mobile-app-title') }}
                </h2>
                <div class="text-2xl sm:text-3xl font-normal py-4">
                    {{ $t('text-mobile-app-subtitle') }}
                </div>
                <div class="flex flex-nowrap pt-6">
                  <div :class="[ 'shrink-0 flex items-center justify-start', deviceLinks.length > 1 ? 'pr-5' : '' ]">
                    <a
                      v-if="links?.android"
                      :href="links.android"
                      :aria-label="$t('text-google-play')"
                    >
                      <NuxtImg
                        :src="`/buttons/${routeLang.code.value}/google-play-badge.png`"
                        format="webp"
                        :alt="$t('text-google-play')"
                        :width="646"
                        :height="250"
                        :modifiers="{ rotate: null }"
                        :sizes="deviceLinks.length > 1 ? '124px sm:217px' : '217px'"
                        :class="[ 'w-auto mb-5', deviceLinks.length > 1 ? 'h-12 sm:h-21' : 'h-21' ]"
                        loading="lazy"
                      />
                    </a>
                  </div>
                  <div class="shrink-0 flex items-center justify-start">
                    <a
                      v-if="links?.ios"
                      :href="links.ios"
                      :aria-label="$t('text-apple-store')"
                    >
                      <img
                        :src="`/buttons/${routeLang.code.value}/apple-store-badge.svg`"
                        :alt="$t('text-apple-store')"
                        :width="120"
                        :height="40"
                        :class="[ 'w-auto mb-5', deviceLinks.length > 1 ? 'h-10 sm:h-16' : 'h-16' ]"
                        loading="lazy"
                      >
                    </a>
                  </div>
                </div>
            </div>
            <div class="flex items-center justify-center">
              <Image
                  src="/mobile-app-preview.png"
                  :alt="$t('text-mobile-app')"
                  :width="651"
                  :height="658"
                  sizes="265px xs:651px"
                  loading="lazy"
              />
            </div>
        </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { getDeviceInfo } from "@utils/get-device-info";
import Image from "@components/ui/image";

const config = useRuntimeConfig();
const routeLang = useRouteLang();
const { ssrContext } = useNuxtApp();
const loading = ref(true);
const links = ref({});

const userAgent = import.meta.client ? navigator.userAgent : ssrContext?.event?.req?.headers['user-agent'];
const { os } = getDeviceInfo(userAgent, routeLang.locale.value);
const device = (os || '').startsWith('Android') ? 'android' : (os === 'iOS' ? 'ios' : os);

const deviceLinks = computed(() => _.filter(_.values(links.value)));

const reload = async () => {
    try {
      links.value = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.APP}`, {
        method: 'GET',
        params: {
          device,
          locale: routeLang.locale.value
        }
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    loading.value = false;
}

onMounted(async () => {
  if (import.meta.server || navigator?.onLine) {
    await reload();
  }
});

</script>

<style scoped>
.linear-gradient {
  background-image: linear-gradient(to bottom right, rgba(164, 251, 123, 0.6) 40%, rgba(250, 238, 69, 0.6));
}
</style>
